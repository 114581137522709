import * as React from 'react';

const TermsPageDe = () => {
	return (
		<>
			<title>Allgemeine Nutzungsbedingungen für die Smart Kitchen Dock App</title>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="Generator" content="Microsoft Word 15 (filtered)" />
			<style
				dangerouslySetInnerHTML={{
					__html: '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:Tahoma;\n\tpanose-1:2 11 6 4 3 5 4 4 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\np.MsoHeader, li.MsoHeader, div.MsoHeader\n\t{mso-style-link:"Header Char";\n\tmargin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\na:link, span.MsoHyperlink\n\t{color:blue;\n\ttext-decoration:underline;}\np.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:36.0pt;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\nspan.HeaderChar\n\t{mso-style-name:"Header Char";\n\tmso-style-link:Header;\n\tfont-family:"Calibri",sans-serif;}\n.MsoChpDefault\n\t{font-size:10.0pt;}\n /* Page Definitions */\n @page WordSection1\n\t{size:612.1pt 792.1pt;\n\tmargin:70.9pt 70.9pt 2.0cm 70.9pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
				}}
			/>
			<div className="WordSection1">
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Allgemeine Nutzungsbedingungen
						</span>
					</b>
				</p>
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							für die Smart Kitchen Dock App
						</span>
					</b>
				</p>
				<p className="MsoNormal">
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal">
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							1.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Vertragsgrundlage
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Home Connect GmbH, Carl-Wery-Straße 34, 81739 München, Deutschland („<b>Betreiber</b>“) überlässt Ihnen unentgeltlich die Smart
						Kitchen Dock App für die Installation und Nutzung auf Ihren mobilen Endgeräten. Weitere Informationen zum Betreiber können Sie dem
						Impressum der Smart Kitchen Dock App entnehmen.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Vorliegende Allgemeine Nutzungsbedingungen („
						<b>Allgemeine Nutzungsbedingungen</b>“) gelten zwischen Ihnen als Nutzer der Smart Kitchen Dock App und dem Betreiber der Smart Kitchen
						Dock App. Nur dieser wird Ihr Vertragspartner. Diese Allgemeinen Nutzungsbedingungen werden mit dem Klick auf die entsprechende
						Schaltfläche im Anmeldeprozess bindende Vertragsgrundlage für die Nutzung der Smart Kitchen Dock App. Sie können den Text dieser
						Allgemeinen Nutzungsbedingungen in der Smart Kitchen Dock App im Bereich{' '}
					</span>
					<span lang="DE">„</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Einstellungen“ einsehen und abspeichern. Es gilt die Sprachfassung des Landes, aus dessen App-Store Sie die Smart Kitchen Dock App
						heruntergeladen haben. In Ländern mit mehreren Landessprachen, kann der Betreiber mehrere Sprachfassungen anbieten.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref457208957">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								2.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Nutzungsvoraussetzungen
							</span>
						</b>
					</a>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Um die Smart Kitchen Dock App nutzen zu dürfen, müssen die nachfolgenden Voraussetzungen erfüllt sein:
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie sind Verbraucher und mindestens 18 Jahre alt,
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie haben sowohl ein Home Connect als auch ein Amazon Alexa Benutzerkonto angelegt; für beide Benutzerkonten gelten jeweils die
						gesonderten Nutzungsbedingungen und Datenschutzinformationen der jeweiligen Anbieter der Benutzerkonten,
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie betreiben das Smart Kitchen Dock in einem Land, in dem der Betreiber die Smart Kitchen Dock App anbietet (Liste der verfügbaren
						Länder auf{' '}
					</span>
					<span lang="EN-US">
						<a href="https://www.home-connect.com">
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								https://www.home-connect.com
							</span>
						</a>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						verfügbar).
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zur Nutzung der Smart Kitchen Dock App ist die Installation auf einem internetfähigen, mobilen Endgerät mit einem geeigneten
						Betriebssystem (für eine Aufstellung der unterstützten Endgeräte siehe
					</span>
					<span lang="EN-US">
						<a href="https://www.home-connect.com">
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								https://www.home-connect.com
							</span>
						</a>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						) sowie ein Smart Kitchen Dock erforderlich. Das mobile Endgerät sowie das Smart Kitchen Dock müssen von Ihnen gestellt werden. Die
						Smart Kitchen Dock App ist nur mit dem Smart Kitchen Dock kompatibel, d.h. sie funktioniert nur mit dem Smart Kitchen Dock. Sie ist
						nicht interoperabel mit anderen Geräten (z.B. anderen Smart Speakern oder Docking-Stationen).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zur Einrichtung des Smart Kitchen Docks muss das Smart Kitchen Dock mit dem mobilen Endgerät per Bluetooth und das mobile Endgerät sowie
						das Smart Kitchen Dock müssen mit dem Wi-Fi-Netzwerk mit einem entsprechenden Internet-Router verbunden werden. &nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Haben Sie das Smart Kitchen Dock einmal über die Smart Kitchen Dock App auf dem mobilen Endgerät gemäß Ziffer{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						eingerichtet, können bestimmte Funktionalitäten des Smart Kitchen Docks (z.B. Sprachsteuerung) auch dann genutzt werden, wenn das Smart
						Kitchen Dock nicht mit dem mobilen Endgerät verbunden ist. Eine Internetverbindung des Smart Kitchen Docks über das Wi-Fi-Netzwerk ist
						aber auch dann erforderlich.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Etwaige für die Datenverbindung entstehende Kosten für die Verbindung des Smart Kitchen Docks und des mobilen Endgeräts (auch für den
						Download von Updates der Smart Kitchen Dock App oder der Steuerungssoftware des Smart Kitchen Dock sowie von Inhalten oder die
						Übertragung von Bildern) sind von Ihnen zu tragen. Der Betreiber haftet nicht für die Verfügbarkeit und/oder Qualität der
						Datenverbindung.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Eine Nutzung der Funktionalitäten des Smart Kitchen Dock sowie der Smart Kitchen Dock App &nbsp;(insbesondere den Zugriff auf die Alexa
						Skills und die Fernsteuerung und –überwachung von Hausgeräten) sind bei deaktivierter Wi-Fi-Verbindung sowie außerhalb eines
						Wi-Fi-Netzwerks nicht möglich. Im Offline-Betrieb kann das Smart Kitchen Dock nur als Bluetooth-Lautsprecher bzw. als Ladegerät
						verwendet werden (d.h. ohne Alexa Funktionalität).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Mit dem Herunterladen der App bestätigen Sie, dass Sie weder Ihren ständigen Wohnsitz in einem Staat haben, der US-Embargo-Vorschriften
						unterworfen ist, noch von der US-Regierung als terrorunterstützender Staat bestimmt wurde und auch nicht persönlich auf einer Liste der
						US-Regierung über verbotene oder eingeschränkte Parteien stehen.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '35.45pt',
						textAlign: 'justify'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref50399744">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								3.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Registrierungs- und Einrichtungsprozess
							</span>
						</b>
					</a>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Nutzung der Smart Kitchen Dock App benötigt keine Registrierung in der Smart Kitchen Dock App oder eine damit verbundene Eingabe von
						Daten. Sie müssen lediglich diese Nutzungsbedingungen akzeptieren. Mit dem Klick auf die entsprechende Schaltfläche kommt der
						Nutzungsvertrag über die Nutzung der Smart Kitchen Dock App zustande.
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Um die vollen Funktionalitäten der Smart Kitchen Dock App und des Smart Kitchen Docks zu nutzen, ist es aber erforderlich, dass sie in
						der Smart Kitchen Dock App die Anmeldedaten für Ihr Amazon Alexa und Ihr Home Connect Benutzerkonto eintragen (vgl. im Detail hierzu
						Ziffer{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						). Bei Eingabefehlern können Sie diese Daten direkt bei der Eingabe korrigieren.{' '}
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Durch die Verknüpfung mit dem Home Connect Benutzerkonto erhalten wir Ihre E-Mail-Adresse, um Sie im Rahmen dieses Nutzungsvertrages
						kontaktieren zu können. Eine Nutzung der E-Mail-Adresse für Werbezwecke erfolgt nicht ohne Ihre vorherige Zustimmung.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Haben Sie sich einmal mit ihrem Amazon Alexa und Ihrem Home Connect Benutzerkonto angemeldet, bleibt der Smart Kitchen Dock solange mit
						Ihrem Amazon Alexa und Ihrem Home Connect Benutzerkonto verbunden, bis sie diese Verbindung wieder aktiv über die Smart Kitchen Dock App
						trennen oder Sie den Smart Kitchen Dock auf Werkseinstellungen zurücksetzen. Durch Entfernen des mobilen Endgeräts wird die Verbindung
						zu Ihrem Amazon Alexa und Ihrem Home Connect Benutzerkonto nicht getrennt. Wenn der Smart Kitchen Dock über Ihr WiFi-Netzwerk verbunden
						ist, können diese Konten daher über ein beliebiges mit dem Smart Kitchen Dock verbundenes mobiles Endgerät oder den Smart Kitchen Dock
						selbst angesteuert werden (zum Vorgehen beim Weiterverkauf vgl. Ziffer{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						0
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref75187508">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								4.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Funktionen und Dienste der Smart Kitchen Dock App
							</span>
						</b>
					</a>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Smart Kitchen Dock App zusammen mit dem von Ihnen bereitzustellenden mobilen Endgerät dient dazu, den Smart Kitchen Dock
						einzurichten und den vollen Funktionsumfang des Smart Kitchen Docks nutzen zu können. Nach der Verknüpfung der Smart Kitchen Dock App
						mit Ihren Amazon Alexa und Home Connect Benutzerkonto können sie dann Alexa Skills über das Smart Kitchen Dock nutzen und Home
						Connect-fähige Hausgeräte („
						<b>Hausgeräte</b>“) über vorgegebene Sprachbefehle ansteuern.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die durch das Smart Kitchen Dock zur Verfügung gestellten Funktionen hängen von dem jeweiligen Hausgerätetyp und dem Land, in dem sich
						der Smart Kitchen Dock und das Hausgerät betrieben wird ab. &nbsp;Sofern für bestimmte Hausgerätetypen zusätzliche Funktionen zur
						Verfügung gestellt werden, können hierfür zusätzliche besondere Nutzungsbedingungen und Datenschutzinformationen gelten. Sofern dies der
						Fall ist, stellen wir Ihnen diese zum Abruf bereit, bevor Sie die jeweilige Funktion aktivieren. Die zusätzlichen besonderen
						Nutzungsbedingungen müssen separat akzeptiert werden und werden dann Teil dieser Vereinbarung. Sowohl die zusätzlichen besonderen
						Nutzungsbedingungen als auch ergänzende Datenschutzinformationen sind in der Smart Kitchen Dock App im Bereich ‚Einstellungen‘ abrufbar.{' '}
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zur Nutzung der durch das Smart Kitchen Dock und die Smart Kitchen Dock App zur Verfügung gestellten Funktionen werden Informationen
						(z.B. Ihre Benutzerkennungen; gestellte Anfragen und Ihre Steuerungsanweisungen für Hausgeräte) über eine Datenverbindung an Server
						übermittelt, die zur Bearbeitung der Anfragen („<b>Amazon Server</b>“) sowie der Ausführung Ihrer Steuerungsanweisungen dienen („
						<b>Home Connect Server</b>“). Weitere Informationen zum Datenumgang können Sie der Datenschutzerklärung entnehmen.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Der volle Funktionsumfang des Smart Kitchen Dock steht Ihnen nur zur Verfügung, wenn Sie (i) über die Smart Kitchen Dock App an Ihrem
						Amazon Alexa und Ihrem Home Connect Benutzerkonto angemeldet sind, (ii) Ihr Smart Kitchen Dock mit Ihrem mobilen Endgerät verbunden („
						<b>Pairing</b>
						“) ist, (iii) Ihr Smart Kitchen Dock Ihrem Amazon Alexa und Ihrem Home Connect Benutzerkonto zugeordnet ist und (iv) sowohl Ihre Smart
						Kitchen Dock eine Bluetooth-Verbindung mit ihrem mobilen Endgerät als auch Ihr mobiles Endgerät &nbsp;und der Smart Kitchen Dock eine
						Datenverbindung zum Amazon und zum Home Connect Server aufgebaut haben.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Aus Sicherheitsgründen ist für einzelne Home Connect Funktionen neben der Befehlseingabe über die Smart Kitchen Dock App zusätzlich eine
						manuelle Bestätigung bzw. eine manuelle Aktivierung an dem Hausgerät selbst erforderlich.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							5.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Nutzung des Smart Kitchen Dock durch mehrere Nutzer
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Ist das Smart Kitchen Dock einmal nach Ziffer{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						über die Smart Kitchen Dock App eingerichtet, können gewissen Funktionen des Smart Kitchen Docks auch ohne das mobile Endgerät verwendet
						werden. Diese Funktionen werden dann über das hinterlegte Amazon Alexa bzw. das hinterlegte Home Connect Benutzerkonto ausgeführt und
						diesen Konten zugerechnet. Wenn Sie verhindern möchten, dass andere Personen, die Zugang zum Smart Kitchen Dock haben solche Aktionen
						ausführen, loggen Sie den Smart Kitchen Dock bitte über die Smart Kitchen Dock App aus Ihrem Amazon Alexa und Ihrem Home Connect
						Benutzerkonto aus.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wenn Sie Dritte den Smart Kitchen Dock benutzen lassen, verpflichten Sie sich, diese Dritten vor der Nutzung über Zweck und Umfang der
						Datenerhebung und die weitere Verarbeitung und Nutzung durch den Betreiber sowie Amazon zu informieren und die Einwilligung aller
						Benutzer in diesen Datenumgang einzuholen.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							6.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							In-App-Nachrichten
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie können auf Ihrem mobilen Endgerät einstellen, ob Sie Benachrichtigungen in Form von In-App -Benachrichtigungen in der Smart Kitchen
						Dock App wünschen.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wenn Sie die Push-Benachrichtigungen zulassen, können wir Sie im Falle von Sicherheitshinweisen und Produktrückrufen über die Smart
						Kitchen Dock App kontaktieren.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref457208967">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								7.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Nutzungsrechte
							</span>
						</b>
					</a>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Smart Kitchen Dock App sowie die davon umfassten Dienste und Inhalte sind umfassend urheberrechtlich geschützt.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie erhalten für die Dauer dieses Nutzungsvertrages ein nicht ausschließliches, nicht unterlizenzierbares, nicht übertragbares und
						widerrufbares Nutzungsrecht, die Smart Kitchen Dock App sowie die davon umfassten Dienste und Inhalte ausschließlich zu den in diesen
						Allgemeinen und den besonderen Nutzungsbedingungen vereinbarten Zwecken und in Einklang mit diesen Nutzungsbedingungen und dem geltenden
						Recht unentgeltlich zu nutzen. Insbesondere dürfen Sie die Smart Kitchen Dock App – soweit nicht durch zwingendes Recht gestattet –
						nicht kopieren, bearbeiten, überarbeiten, verändern, dekompilieren (Reverse Engineering) oder umwandeln. Die Smart Kitchen Dock App ist
						nur mit Home Connect fähigen Hausgeräten kompatibel.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							8.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Nutzung von Inhalten
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wenn Sie vorgeschlagene Inhalte (z.B. Kochrezepte) nutzen, vergewissern Sie sich bitte vorher, dass diese Inhalte für Sie persönlich
						geeignet sind. Nutzen Sie daher zum Beispiel Gerichte bzw. vorgeschlagenen Zutaten nur dann, wenn diese kein Gesundheitsrisiko im
						Hinblick auf Ihre individuelle gesundheitliche Verfassung darstellen (z.B. wegen Allergien). Bitte überprüfen Sie bei vorgeschlagenen
						Haltbarkeits-/Verzehrempfehlungen die Lebensmittel vor der Verwendung, dass diese tatsächlich noch unbedenklich zum Verzehr geeignet
						sind. Wenn Sie auf Ihrem Hausgerät (z.B. dem Cookit) selber Rezepte erstellen, werden diese nicht von uns geprüft. Bitte erstellen Sie
						diese Rezepte sorgsam, um etwaige Gesundheits- oder Sachschäden zu vermeiden. Bitte überprüfen Sie vorgeschlagene Programme (z.B. Wasch-
						oder Trockenprogramme) vor dem Start ebenfalls nochmal.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							9.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Verfügbarkeit von Funktionen und Diensten
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Der Betreiber unternimmt angemessene Anstrengungen, alle Funktionalitäten und Dienste der Smart Kitchen Dock App sowie die Integration
						und Koppelung mit Diensten Dritter zur Verfügung zu stellen. Es kann jedoch zu technischen Schwierigkeiten kommen, die zu einer
						zeitweiligen Unterbrechung der Verfügbarkeit führen. Sie können aus einer Unterbrechung der Verfügbarkeit keine Ansprüche gegen den
						Betreiber ableiten.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref458099389">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								10.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Dienste Dritter
							</span>
						</b>
					</a>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
							– insbesondere Amazon Alexa und Home Connect
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In die Smart Kitchen Dock App integrierte Dienste von Drittanbietern (z.B. Amazon Alexa oder Home Connect) erfordern die Koppelung der
						Smart Kitchen Dock App mit Ihren bestehenden Drittanbieter-Benutzerkonten (vgl. Ziffer{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						). Für diese Kopplung ist eine Internetverbindung erforderlich.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Etwaige für die Nutzung solcher Dienste geltenden besonderen Nutzungsbedingungen des Betreibers müssen vor der Nutzung dieser Dienste
						ggf. separat akzeptiert werden. Bitte beachten Sie, dass neben den Allgemeinen Nutzungsbedingungen des Betreibers sowie den besonderen
						Nutzungsbedingungen für integrierte bzw. externe Dienste zusätzlich Nutzungsbedingungen des Drittanbieters gelten. Für Letztere zeichnet
						sich allein der Drittanbieter verantwortlich.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Koppelung mit externen Diensten (z.B. Amazon Alexa) stellt eine Funktionalität dar, auf die der Betreiber nur bedingt Einfluss hat.
						Er kann für die Verfügbarkeit dieser Dienste daher nicht einstehen. Der Betreiber kann die vom Drittanbieter übermittelten Daten in der
						Regel nicht auf Richtigkeit und Vollständigkeit überprüfen und übernimmt insoweit keine Haftung.
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							11.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Software-Updates
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die volle Funktionsfähigkeit und Funktionssicherheit der Smart Kitchen Dock App und des Smart Kitchen Dock kann nur bei zeitnaher
						Installation der zur Verfügung gestellten Updates der Smart Kitchen Dock App sowie des Smart Kitchen Docks sichergestellt werden. Ein
						Update der Smart Kitchen Dock App kann es erfordern, dass Sie das Betriebssystem Ihres mobilen Endgerätes aktualisieren müssen, um die
						Smart Kitchen Dock App weiter nutzen zu können. Updates des Smart Kitchen Docks dienen z.B. zur Verbesserung bestehender
						Funktionalitäten, Korrektur von Fehlern oder Ergänzung weiterer Funktionen. Bei der ersten Verbindung des Smart Kitchen Docks mit dem
						Home Connect Server wird die auf dem Smart Kitchen Dock enthaltene Software automatisch aktualisiert. Vor dem nächsten Update Ihres
						Smart Kitchen Docks müssen Sie bzw. – bei mehreren Nutzern – ein beliebiger Nutzer das Herunterladen und die Installation in der Smart
						Kitchen Dock App bestätigen. Für weitere Updates des Smart Kitchen Docks können Sie – bzw. bei mehreren Nutzern – jeder Nutzer zwischen
						verschiedenen Varianten wählen (z.B. ob solche Updates generell standardmäßig heruntergeladen und installiert werden, ob dies nur bei
						sicherheitsrelevanten Updates der Fall sein soll oder ob bei jedem Update jeweils einzeln über das Herunterladen und die Installation
						entschieden werden soll). Diese Einstellung kann bei mehreren Nutzern jeder Nutzer (auch mit Wirkung für andere Nutzer) nachträglich
						jederzeit in der App ändern. Je nach gewählter Einstellung werden Sie dann über die Smart Kitchen Dock App über bereitgestellte Updates
						für Ihr Smart Kitchen Dock informiert. &nbsp;Nur in ganz besonderen Ausnahmenfällen (z.B. in Fällen, in denen die Produkt- oder
						Datensicherheit es erfordert <span style={{ display: 'none' }}>oder bei bestimmten Gerätetypen</span>) behalten wir uns generell das
						Recht vor, Updates des Smart Kitchen Docks unabhängig von den Einstellungen direkt herunterzuladen und einzuspielen.
					</span>
					<span lang="DE"> </span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie erklären sich mit einer solchen automatischen Aktualisierung des Smart Kitchen Docks einverstanden. Falls wir gezwungen sein
						sollten, eine solche automatische Aktualisierung vorzunehmen, werden wir Sie aber darüber informieren. Während der Installation eines
						Updates des Smart Kitchen Docks (unabhängig von der Art des Updates) darf der Smart Kitchen Dock nicht vom Strom genommen und die
						Internetverbindung nicht unterbrochen werden.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Der Betreiber haftet nicht für Fehler der Smart Kitchen Dock App oder des Smart Kitchen Docks und Schäden, die darauf zurückzuführen
						sind, dass Sie bereitgestellte Updates der App oder des Smart Kitchen Docks nicht, nicht innerhalb einer angemessenen Frist oder nicht
						vollständig heruntergeladen und/oder eingespielt haben, sofern wir Sie über die Verfügbarkeit der Aktualisierung und die Folgen einer
						unterlassenen Installation informiert haben und die unterlassene oder unsachgemäße Installation der Aktualisierung nicht auf eine Ihnen
						zur Verfügung gestellten mangelhaften Installationsanleitung zurückzuführen ist. Sie sollten daher die zur Verfügung gestellten Updates
						unverzüglich herunterladen und einspielen. Über die Bereitstellung von Updates für Smart Kitchen Docks informieren wir Sie in der Smart
						Kitchen Dock App.
						<a name="_Ref78984274" />
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							12.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Umzug / Weiterverkauf / Nutzung durch Dritte
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Ziehen Sie Ihren Smart Kitchen Dock in ein anderes Land als das der ursprünglichen Inbetriebnahme um, so sind Sie verpflichtet, die
						Ländereinstellung in Ihrem Alexa und Home Connect Benutzerkonto entsprechend umzustellen. Bitte beachten Sie, dass die Nutzung der Smart
						Kitchen Dock App in einem anderen Land anderen Nutzungsbedingungen und einer anderen Datenschutzerklärung unterliegen kann. Wird Ihr
						Land in der Liste der unterstützen Länder nicht aufgeführt, so ist die weitere Nutzung der Smart Kitchen Dock App unzulässig. Die
						Nutzung der Smart Kitchen Dock App in Verbindung mit einem Smart Kitchen Dock, welches nicht für eine Nutzung in dem jeweiligen Land
						zugelassen ist, ist unzulässig.
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Im Falle des Verkaufs oder der dauerhaften Überlassung Ihres mobilen Endgeräts an Dritte löschen Sie die Smart Kitchen Dock App. Im
						Falle des Verkaufs des Smart Kitchen Docks ist es wichtig, dass Sie den Smart Kitchen Dock in den Werkszustand zurück setzen. In diesem
						Fall wird die Verknüpfung des Smart Kitchen Docks zu Ihrer Smart Kitchen Dock App und Ihrem Alexa und Home Connect Benutzerkonto
						aufgelöst. Andernfalls kann diese Beziehung weiter bestehen bleiben.{' '}
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Haben Sie Ihren Smart Kitchen Dock gebraucht erworben, empfehlen wir, ihn nochmals in den Werkszustand zurückzusetzen. Weitere
						Informationen zum Datenschutz können Sie der Datenschutzerklärung entnehmen.
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Räumen Sie – entgegen der Vorgaben aus Ziffer{' '}
					</span>
					<b>
						<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Error! Reference source not found.
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						und{' '}
					</span>
					<b>
						<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Error! Reference source not found.
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						dieser Allgemeinen Nutzungsbedingungen – Dritten die Nutzungsmöglichkeit Ihrer Smart Kitchen Dock App über das mobile Endgerät, auf dem
						die Smart Kitchen Dock App installiert ist, ein, sind Sie dafür verantwortlich, dass die Smart Kitchen Dock App im Einklang mit diesen
						Nutzungsbedingungen und den anwendbaren Gesetzen genutzt wird.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref53042386">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								13.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Haftung
							</span>
						</b>
					</a>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Der Betreiber haftet Ihnen gegenüber bei Vorsatz, grober Fahrlässigkeit und arglistig verschwiegenen Mängeln für alle vom Betreiber
						sowie seinen gesetzlichen Vertretern und Erfüllungsgehilfen verursachten Schäden unbegrenzt. Bei leichter Fahrlässigkeit haftet der
						Betreiber Ihnen im Fall der Verletzung des Lebens, des Körpers oder der Gesundheit unbeschränkt. Im Übrigen haftet Ihnen der Betreiber
						nur, soweit er eine wesentliche Vertragspflicht, mithin eine Pflicht deren Erfüllung die ordnungsgemäße Durchführung dieses Vertrags
						überhaupt erst ermöglicht und auf deren Einhaltung Sie als Vertragspartner regelmäßig vertrauen und vertrauen dürfen, verletzt hat. In
						diesen Fällen ist die Haftung auf den Ersatz des vorhersehbaren, typischerweise eintretenden Schadens beschränkt. Die
						verschuldensunabhängige Haftung des Betreibers auf Schadensersatz (§ 536a BGB) für bei Vertragsschluss vorhandene Mängel ist nach § 578b
						Abs. 1 Nr. 1 BGB nicht anzuwenden. Die Haftung des Betreibers nach den Bestimmungen des Produkthaftungsgesetzes bleibt von den
						vorstehenden Bestimmungen unberührt.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							14.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Gewährleistung
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Für die vom Betreiber bereitgestellte Smart Kitchen Dock App gelten die gesetzlichen Gewährleistungsrechte für digitale Produkte.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							15.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Änderung der inhaltlichen Funktionalitäten der Smart Kitchen Dock App
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '21.3pt', textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Der Betreiber behält sich vor, an der Smart Kitchen Dock App Änderungen vorzunehmen, die über das zur Aufrechterhaltung der
						Vertragsmäßigkeit der Smart Kitchen Dock App erforderliche Maß hinausgehen. Dies kann z.B. dann der Fall sein, wenn (i) die Anpassung an
						neue Technologien oder die Produkt- oder Datensicherheit es erfordern; (ii) bestimmte Funktionalitäten der Smart Kitchen Dock App nicht
						mehr von einer hinreichend großen Anzahl von Kunden nachgefragt oder genutzt werden; (iii) Missbrauch oder Schäden verhindert werden
						sollen oder (iv) diese Funktionalitäten nicht mehr mit wirtschaftlich vertretbarem Aufwand angeboten werden können. Gleiches gilt für
						Änderungen, die durch äußere Umstände entstehen (z.B. Einstellung oder Änderungen der Services von Partnern wie Amazon Alexa;
						Gesetzesänderungen oder geänderte Marktklage).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wenn sich eine Änderung negativ auf Ihre Nutzung der Smart Kitchen Dock App auswirkt, werden wir Sie hierüber innerhalb einer
						angemessenen Frist vorab ausdrücklich informieren (insbesondere über die Merkmale und den Zeitpunkt der Änderung). Eine Ausnahme besteht
						in dringenden Fällen, in denen die Änderungen dazu dienen, Missbrauch oder Schäden zu verhindern, auf rechtliche Erfordernisse zu
						reagieren oder die Sicherheit und Funktionsfähigkeit der Smart Kitchen Dock App zu gewährleisten.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wird durch die Änderung Ihre Zugriffsmöglichkeit auf die Smart Kitchen Dock App oder deren Nutzbarkeit für Sie nicht nur unerheblich
						beeinträchtigt, können Sie den Nutzungsvertrag über die Smart Kitchen Dock App jederzeit nach Ziffer{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						18
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						unentgeltlich been
					</span>
					<span lang="DE">den</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							16.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Änderung der Allgemeinen und besonderen Nutzungsbedingungen
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Der Betreiber ist berechtigt, Änderungen an den Allgemeinen Nutzungsbedingungen sowie an den besonderen Nutzungsbedingungen für weitere
						Dienste des Betreibers unter den folgenden Voraussetzungen für die Zukunft vorzunehmen:
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Änderungen an den jeweiligen Nutzungsbedingungen, welche sich auf die Vertragsbeziehung auswirken, werden wir Ihnen in der Regel mit
						einem Vorlauf von nicht weniger als sechs Wochen vor dem Zeitpunkt des Wirksamwerdens der Änderung über die Smart Kitchen Dock App
						und/oder per E-Mail mitteilen. Die Mitteilung wird auch Informationen über Ihr Recht, die Änderungen abzulehnen und die sich daraus
						ergebenden Konsequenzen enthalten. Sofern Sie der Änderung nicht innerhalb der in der Ankündigung genannten Frist (regelmäßig 6 Wochen)
						widersprechen, gilt dies als Einverständnis mit der Änderung ("Zustimmungsfiktion"); hierauf werden wir in der Ankündigung gesondert
						hinweisen. Die Zustimmungsfiktion gilt nicht für eine Veränderung, welche eine Hauptleistung des Nutzungsvertrages betrifft, sofern
						dadurch ein ungünstiges Missverhältnis zwischen Leistung und Gegenleistung zu Ihren Lasten entstehen würde. Im Falle eines Widerspruchs
						wird der Nutzungsvertrag zu den bisherigen Bedingungen fortgesetzt. Der Betreiber ist aber berechtigt, Ihren Nutzungsvertrag mit dem
						Zeitpunkt des Wirksamwerdens der Änderung zu beenden.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Redaktionelle Änderungen dieser Nutzungsbedingungen, d.h. Änderungen, die das Vertragsverhältnis nicht betreffen, wie z.B. die Korrektur
						von Tippfehlern, werden ohne Benachrichtigung vorgenommen.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							17.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;<a name="_Ref14357609">Verwendung der Smart Kitchen Dock App</a>
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie dürfen die Smart Kitchen Dock App nicht für unerlaubte (z.B. militärische) Zwecke nutzen. Sie sind nicht berechtigt, die Dienste,
						Inhalte, Daten und Informationen, die Sie im Zusammenhang mit der Nutzung der Smart Kitchen Dock App erhalten, gewerblich zu vertreiben
						oder zu verarbeiten. Der Betreiber kann bei einer unzulässigen bzw. unberechtigten Nutzung der Smart Kitchen Dock App die geeigneten
						Maßnahmen einleiten und dabei unter anderem auch Nutzer sperren.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref96433692">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								18.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Laufzeit und Beendigung des Nutzungsvertrags
							</span>
						</b>
					</a>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Der Nutzungsvertrag läuft auf unbestimmte Zeit. Sie können den Nutzungsvertrag jederzeit kündigen. Der Betreiber ist berechtigt, den
						Nutzungsvertrag mit Ihnen ordentlich mit einer Frist von sechs Wochen oder kürzer im Falle, dass der Betreiber beschließt, den Betrieb
						der Smart Kitchen Dock App einzustellen, zu kündigen. Im Übrigen bleibt das Recht beider Vertragsparteien, den Vertrag aus wichtigem
						Grund zu kündigen, unberührt. Ein wichtiger Grund liegt für den Betreiber insbesondere dann vor, wenn Sie gegen wesentliche Pflichten
						dieser Allgemeinen Nutzungsbedingungen (z.B. Ziffern{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						2
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						,{' '}
					</span>
					<b>
						<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Error! Reference source not found.
						</span>
					</b>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						oder{' '}
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						17
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						5) oder der besonderen Nutzungsbedingungen verstoßen oder wenn Ihr Home Connect oder Amazon Alexa Benutzerkonto nicht mehr besteht oder
						der Home Connect bzw. der Amazon Alexa Service eingestellt wird. In letzterem Fall ist eine Anmeldung in der Smart Kitchen Dock App
						nicht mehr möglich.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							19.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Widerrufsrecht
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Als Verbraucher, d.h. als eine natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer
						gewerblichen, noch selbständigen beruflichen Tätigkeit zugerechnet werden können, steht Ihnen bei allen kostenpflichtigen Diensten,
						welche von dem Betreiber in der Smart Kitchen Dock App angeboten werden, binnen vierzehn Tagen ein Widerrufsrecht zu. Dieses
						Widerrufsrecht bezieht sich nur auf den jeweiligen vom Betreiber in der Smart Kitchen Dock App angebotenen kostenpflichtigen Dienst. Der
						Kaufvertrag über den Smart Kitchen Dock oder das Home Connect fähige Geräte bzw. ein in diesem Zusammenhang etwaig bestehendes
						Widerrufsrecht bleibt unberührt.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Widerrufsbelehrung
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Widerrufsrecht
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Nutzungsvertrag über die Nutzung der Smart Kitchen Dock App zu
						widerrufen.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (der Home Connect GmbH, Carl-Wery-Straße 34, 81739 München, Deutschland,{' '}
					</span>
					<span lang="EN-US">
						<a href="mailto:info.de@home-connect.com">
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								info.de@home-connect.com
							</span>
						</a>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						; +49 (0) 89 / 4590 01) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder eine E-Mail) über Ihren
						Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch
						nicht vorgeschrieben ist.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
						Widerrufsfrist absenden.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Folgen des Widerrufs
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wenn Sie diesen Nutzungsvertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen unterdiesem Nutzungsvertrag erhalten
						haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der
						Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab
						dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
						verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
						ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns bei kostenpflichtigen
						Dienstleistungen einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
						Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten kostenpflichtigen Dienstleistungen im Vergleich zum
						Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Das Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht auf einem körperlichen Datenträger befindlichen digitalen
						Inhalten auch dann, wenn der Betreiber mit der Ausführung des Vertrags begonnen hat, nachdem Sie (i) ausdrücklich zugestimmt haben, dass
						der Betreiber mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und (ii) Sie Ihre Kenntnis davon bestätigt haben,
						dass Sie durch Ihre Zustimmung mit Beginn der Ausführung des Vertrags Ihr Widerrufsrecht verlieren.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Muster-Widerrufsformular
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						An: Home Connect GmbH, Carl-Wery-Straße 34, 81739 München, Deutschland oder{' '}
					</span>
					<span lang="EN-US">
						<a href="mailto:info.de@home-connect.com">
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								info.de@home-connect.com
							</span>
						</a>
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der
						folgenden Dienstleistung (*)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–Bestellt am (*)/erhalten am (*)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–Name des/der Verbraucher(s)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–Anschrift des/der Verbraucher(s)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						–Datum
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						_________
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						(*) Unzutreffendes streichen.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Für Services von Partnern sind die Widerrufsbelehrungen der jeweiligen Partner zu beachten.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							20.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Online-Streitbeilegungsplattform, Teilnahme am Streitbeilegungsverfahren
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform ist unter{' '}
					</span>
					<span lang="EN-US">
						<a href="https://ec.europa.eu/consumers/odr/">
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								https://ec.europa.eu/consumers/odr/
							</span>
						</a>
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						erreichbar.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Wir sind nicht verpflichtet an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen und haben uns gegen
						eine freiwillige Teilnahme daran entschieden. Sofern Sie Beanstandungen, Beschwerden, Fragen oder Anmerkungen haben, können Sie sich
						gerne an die unter den im Impressum der Smart Kitchen Dock App genannten Kontaktdaten an uns wenden.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							21.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Anwendbares Recht / Gerichtsstand / Salvatorische Klausel{' '}
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Es gelten die zwingenden Vorschriften des Rechts, in dem Sie sich gewöhnlich aufhalten. Im Übrigen gilt das Recht der Bundesrepublik
						Deutschland. Die Bestimmungen des UN-Kaufrechts finden unabhängig vom anwendbaren Recht keine Anwendung. Für alle Streitigkeiten, die
						sich aus oder in Zusammenhang mit diesen Allgemeinen und den besonderen Nutzungsbedingungen ergeben, ist Gerichtsstand München,
						Deutschland.
					</span>
					<span lang="DE"> </span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Zwingende gesetzliche Gerichtsstände bleiben unberührt.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Sollte eine Bestimmung dieser Allgemeinen oder der besonderen Nutzungsbestimmungen unwirksam sein oder werden, so bleibt die Wirksamkeit
						der übrigen Bestimmungen hiervon unberührt.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" align="right" style={{ textAlign: 'right' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Version: V1.0.0 September 2022
					</span>
				</p>
				<p className="MsoNormal">
					<span lang="DE">&nbsp;</span>
				</p>
			</div>
		</>
	);
};

export default TermsPageDe;
